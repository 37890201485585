<template>
  <div>
    <q-chip color="primary" text-color="white" square>
      {{ codeTableTemp.supplierStatusMapping[value] }}
    </q-chip>
  </div>
</template>

<script>
export default {
  name: "ProReportSupplierStatusTdField",
  props: {
    value: null,
    filterOption: {},
    codeTableTemp: {
      type: Object,
      required: true,
    },
  },
};
</script>
